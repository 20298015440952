import { useCryptoLink } from 'hooks/encryption/useCryptoLink';
import { useSpaceFile } from 'hooks/encryption/useSpaceFile';
import { useAPI } from 'hooks/useAPI';
import JSZip from 'jszip';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API_ROUTES from 'services/apiRoutes';
import { downloadFileFromStorageWithProgress } from 'services/awsFileUpload';
import useAlert from 'store/useAlert';
import {
  checkForExistingSenderKey,
  getErrorMessage,
  sleep,
} from 'utils/helper';
import JSZipUtils from 'utils/jszipUtil';
import saveAs from 'file-saver';
import userSpaceStore from 'store/userSpaceStore';
import { useParams } from 'react-router-dom';
import useModalStore from 'store/useModalStore';
import useFileStore from 'store/useFileStore';
import useSpaceFiles from './useSpaceFiles';

const useFileOperations = (items: AccordionItem[]) => {
  const { t } = useTranslation();
  const { spaceId, receiverId } = useParams<{
    spaceId: string;
    receiverId: string;
  }>();

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [progressCancelled, setProgressCancelled] = useState(false);

  const downloadProgress = useRef<number>(0);
  const deleteProgressRef = useRef<number>(0);
  const filesRef = useRef<any[]>([]);

  const {
    isCancelled,
    selectedFiles,
    setSelectedFiles,
    setProgressDetails,
    isLoading,
    setLoading,
    setProgressPercentage,
    updateFileStatus,
    clearStoreData,
  } = useFileStore((state) => state);
  const { receiverData } = userSpaceStore();
  const setAlert = useAlert((state) => state.setAlert);
  const { setOpenModal } = useModalStore();

  const { put, del } = useAPI();
  const { generateSharedFilesLink } = useCryptoLink();
  const { decryptFileData, decryptGuestFileData, decryptReceiverFileData } =
    useSpaceFile();
  const { getSpaceFiles, getStorageInfo } = useSpaceFiles();
  const progressCancelledRef = useRef(false);

  useEffect(() => {
    if (isCancelled) {
      progressCancelledRef.current = progressCancelled;
      setProgressCancelled((_prev) => true);
    }
  }, [isCancelled, progressCancelled]);

  useEffect(() => {
    if (!progressCancelled) {
      progressCancelledRef.current = false;
      setLoading(false);
      clearStoreData();
    }
  }, [clearStoreData, progressCancelled, setLoading]);

  const onUpdateSelectedItems = (selectedFileArray: string[]) => {
    setSelectedFiles(selectedFileArray);
  };

  const handleCheckboxChange = (
    item: AccordionItem,
    isChecked: boolean,
    isChild: boolean = false
  ) => {
    let updatedSelectedItems = [...selectedFiles];
    if (isChecked) {
      updatedSelectedItems.push(item.id);
      console.log('from useFile if', updatedSelectedItems);

      if (!isChild) {
        item.children?.forEach((child) => {
          if (!updatedSelectedItems.includes(child.id)) {
            updatedSelectedItems.push(child.id);
          }
        });
      } else {
        const parentItem = findParentItem(items, item.id);
        if (
          parentItem &&
          parentItem.children?.every((child) =>
            updatedSelectedItems.includes(child.id)
          )
        ) {
          updatedSelectedItems.push(parentItem.id);
        }
      }
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(
        (id) => id !== item.id
      );
      if (!isChild) {
        item.children?.forEach((child) => {
          updatedSelectedItems = updatedSelectedItems.filter(
            (id) => id !== child.id
          );
        });
      } else {
        const parentItem = findParentItem(items, item.id);
        if (parentItem) {
          updatedSelectedItems = updatedSelectedItems.filter(
            (id) => id !== parentItem.id
          );
        }
      }
    }
    console.log(updatedSelectedItems);
    onUpdateSelectedItems(updatedSelectedItems);
    // setSelectedFiles(updatedSelectedItems);
  };

  const findParentItem = (
    items: AccordionItem[],
    childId: string
  ): AccordionItem | null => {
    for (let item of items) {
      if (item.children?.some((child) => child.id === childId)) {
        return item;
      } else if (item.children) {
        const found = findParentItem(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  const toggleExpand = (id: string) => {
    const updatedExpandedItems = expandedItems.includes(id)
      ? expandedItems.filter((expandedId) => expandedId !== id)
      : [...expandedItems, id];
    setExpandedItems(() => updatedExpandedItems);
  };

  const getSelectedItems = () => {
    const files: AccordionItem[] = [];
    const printItems = (items: AccordionItem[]): string[] => {
      return items.reduce((acc: string[], item) => {
        if (item.children) {
          acc.push(...printItems(item.children));
        } else if (selectedFiles?.includes(item.id)) {
          acc.push(item.id);
          files.push(item);
        }
        return acc;
      }, []);
    };

    const selectedTitles = printItems(items);
    return files;
  };

  const onFilesStatusChange = (bulkId: string) => {
    const files = items.filter((item) => item.id === bulkId)[0];
    if (files) {
      if (files?.children?.length) {
        files.children?.map((file) => {
          return put(API_ROUTES.updateFileStatus(file.id), {}).then(() => {
            getSpaceFiles();
            setIsModalOpen(false);
          });
        });
      } else if (files.id) {
        return put(API_ROUTES.updateFileStatus(files.id), {}).then(() => {
          setIsModalOpen(false);
          getSpaceFiles();
        });
      }
    }
  };

  const generateUniqueFilename = (
    filename: string,
    filenameMap: Map<string, number>
  ): string => {
    const fileParts = filename.split('.');
    const baseName = fileParts.slice(0, -1).join('.') || 'file';
    const extension = fileParts.length > 1 ? `.${fileParts.pop()}` : '';

    let uniqueFilename = filename;

    if (filenameMap.has(filename)) {
      let count = filenameMap.get(filename)! + 1;
      filenameMap.set(filename, count);
      uniqueFilename = `${baseName}(${count})${extension}`;
    } else {
      filenameMap.set(filename, 0);
    }

    return uniqueFilename;
  };

  const downloadMultiFiles = async (urls = [], files?: AccordionItem[]) => {
    const filenameMap = new Map<string, number>();
    const filenames =
      files?.map((item: any) => {
        const fullName = item?.title || item?.fileName || 'file';
        return generateUniqueFilename(fullName, filenameMap);
      }) || [];

    const zip = new JSZip();
    const zipFilename = 'post-space-files.zip';

    const fetchFile = async (file: any, index: number) => {
      try {
        const fileArrayBuffer = await file.arrayBuffer();
        const filename = filenames[index] || `file${index}`;
        zip.file(filename, fileArrayBuffer, { binary: true });
      } catch (error) {
        console.error(`Error processing file at index ${index}:`, error);
        throw error;
      }
    };

    try {
      if (progressCancelledRef.current) {
        setProgressCancelled(false);
        return;
      }
      await Promise.all(
        urls.map(async (url, index) => await fetchFile(url, index))
      );
      const zipFile = await zip.generateAsync({ type: 'blob' });
      saveAs(zipFile, zipFilename);
    } catch (err) {
      console.error('Error downloading files:', err);
    } finally {
      setLoading(false);
    }
  };

  const onDecryptFile = async (file: File) => {
    console.log(file)
    if (progressCancelledRef.current) return;
    if (receiverData?.isGuest && (receiverData.receiverId || spaceId)) {
      const senderKey = checkForExistingSenderKey(
        receiverData.receiverId || spaceId || ''
      );
      console.log(senderKey, "senderKey")
      if (receiverData.transferKey && senderKey) {
        console.log('Guest');
        // const senderKey = decryptSharedKeyByBase64(sharedKey);
        return await decryptGuestFileData(
          file,
          senderKey,
          receiverData.transferKey
        );
      }
    } else if (receiverData?.encryptedSenderKey && receiverData.transferKey) {
      console.log('Not Guest, not owner');
      return await decryptReceiverFileData(
        file,
        receiverData.transferKey,
        receiverData.encryptedSenderKey
      );
    } else {
      console.log('OWNER');
      return await decryptFileData(file);
    }
  };

  // const sleep = (msec: number) =>
  //   new Promise((resolve) => setTimeout(resolve, msec));

  const downloadFileById = async (
    filePath: string,
    fileName: string,
    currentFileIndex: number = 0
  ) => {
    if (progressCancelledRef.current) return;

    updateFileStatus(currentFileIndex, 0);
    await sleep(1000);
    return await downloadFileFromStorageWithProgress(
      filePath,
      fileName,
      (data: number) => {
        downloadProgress.current = data;
      }
    ).then(async (response) => {
      if (response) {
        console.log(response)
        updateFileStatus(currentFileIndex, 1);
        await sleep(1000);
        const decryptedFile: any = await onDecryptFile(response);
        if (decryptedFile) {
          updateFileStatus(currentFileIndex, 2);
          await sleep(800);
        }
        return Promise.resolve(decryptedFile);
      }
    });
  };

  const onDownloadFiles = async (bulkId: string) => {
    clearStoreData();
    setProgressCancelled((_prev) => false);
    setProgressPercentage(0);
    let files: AccordionItem | undefined;
    items.some((item) => {
      if (item.id === bulkId) {
        files = item;
        return true;
      } else if (item.children) {
        item.children.some((child) => {
          if (child.id === bulkId) {
            files = child;
            return true;
          }
          return false;
        });
        return files !== undefined;
      }
      return false;
    });
    try {
      if (files) {
        setProgressDetails(
          files.children ? files.children : [files],
          downloadProgress,
          'download'
        );
        filesRef.current = files.children ? files.children : [files];
        setOpenModal('fileProgressModal');
        if (files.children) {
          setLoading(true);
          let downloadedFilesCount = 0;
          const decryptedFiles = await Promise.all(
            files.children?.map(async (file, index) => {
              if (file?.filePath) {
                const response = await downloadFileById(
                  file?.filePath,
                  file.title,
                  index
                );
                if (response) {
                  downloadedFilesCount++;
                  const averageProgress =
                    (downloadedFilesCount / (files?.children?.length || 1)) *
                    100;
                  downloadProgress.current = averageProgress;
                  setProgressPercentage(averageProgress);
                }
                return response;
              }
              return null;
            })
          );
          const validDecryptedFiles: any = await decryptedFiles.filter(
            (file) => file !== null
          );
          if (validDecryptedFiles?.length) {
            await downloadMultiFiles(validDecryptedFiles, files.children);
          }
        } else if (files?.filePath) {
          const decryptedFile = await downloadFileById(
            files?.filePath,
            files.title,
            0
          );
          setProgressPercentage(100);
          if (progressCancelledRef.current) return;
          if (decryptedFile) saveAs(decryptedFile, files.title);
        }
      }
    } catch (e) {
      console.log('ERROR ===', e);
    }
  };

  const onDownloadAllFilesNew = async () => {
    const files = getSelectedItems();
    const filesId = files.map((file) => file.id);
    console.log(filesId);
    await put('/user/spaceFile/download',{
      fileIds: filesId,
    }).then((response) => {
      console.log(response);
    });
  }

  const onDownloadAllFiles = async () => {
    clearStoreData();
    setProgressCancelled((_prev) => false);
    setProgressPercentage(0);
    const files = getSelectedItems();
    console.log(files, "from func")
    if (files.length) {
      filesRef.current = files;

      setProgressDetails(files, downloadProgress, 'download');
      setOpenModal('fileProgressModal');
      setLoading(true);
      let downloadedFilesCount = 0;

      const decryptedFiles = await Promise.all(
        files?.map(async (file, index) => {
          if (file?.filePath) {
            const response = await downloadFileById(
              file?.filePath,
              file.title,
              index
            );
            if (response) {
              downloadedFilesCount++;
              const averageProgress =
                (downloadedFilesCount / (files?.length || 1)) * 100;
              downloadProgress.current = averageProgress;
              setProgressPercentage(averageProgress);
            }
            return response;
          }
          return null;
        })
      );
      const validDecryptedFiles: any = decryptedFiles.filter(
        (file) => file !== null
      );
      if (validDecryptedFiles?.length > 1) {
        downloadMultiFiles(validDecryptedFiles, files);
      } else {
        setProgressPercentage(100);
        if (progressCancelledRef.current) return;
        saveAs(validDecryptedFiles[0], files[0].title);
      }
    }
  };

  const deleteFileBydId = async (
    fileId: string,
    currentFileIndex: number = 0
  ) => {
    await sleep(500);
    updateFileStatus(currentFileIndex, 0);
    return await del(API_ROUTES.deleteFileByFileId(fileId))
      .then(async (response: any) => {
        await sleep(500);
        if (response) updateFileStatus(currentFileIndex, 1);
        return 1;
      })
      .catch((e) => {
        setAlert(t(getErrorMessage(e?.subStatusCode)));
        return 0;
      });
  };

  const newDeleteFileBydId = async (fileId: string) => {
    console.log(fileId);
    // return await del(API_ROUTES.deleteFileByFileId(fileId))
    //   .then((response: any) => {
    //     return response;
    //   })
    //   .catch((e) => {
    //     setAlert(t(getErrorMessage(e?.subStatusCode)));
    //     return 0;
    //   });
  };

  const onDeleteFiles = async (bulkId: string) => {
    let files: AccordionItem | undefined;
    items.find(
      (item) =>
        item.id === bulkId ||
        (item?.children &&
          item.children.find((child) => {
            files = child;
            return child.id === bulkId;
          }))
    );
    if (!files) files = items.filter((item) => item.id === bulkId)[0];
    if (files) {
      setDeleteModal(false);
      setLoading(true);
      setProgressDetails(
        files?.children ? files?.children : [files],
        deleteProgressRef,
        'delete'
      );
      filesRef.current = files?.children ? files?.children : [files];
      setOpenModal('fileProgressModal');
      const totalFilesToDelete = files?.children ? files?.children.length : 1;
      let deletedFilesCount = 0;
      if (files?.children?.length) {
        files.children?.forEach(async (file, index) => {
          const success = await deleteFileBydId(file.id, index);
          if (success) {
            deletedFilesCount++;
            const averageProgress =
              (deletedFilesCount / totalFilesToDelete) * 100;
            deleteProgressRef.current = averageProgress;
            setProgressPercentage(averageProgress);
            if (deletedFilesCount === totalFilesToDelete) {
              getSpaceFiles();
              // closeModal();
              setSelectedFiles([]);
              setLoading(false);
              getStorageInfo();
            }
          }
        });
      } else {
        const success = await deleteFileBydId(files.id, 0);
        if (success) {
          setProgressPercentage(100);
          getSpaceFiles();
          // closeModal();
          setSelectedFiles([]);
          getStorageInfo();
          setLoading(false);
        }
      }
    }
  };

  const onDeleteAllFilesNew = async () => {
    const files = getSelectedItems();
    setDeleteModal(false);
    const filesId = files.map((file) => file.id);
    console.log(filesId);
    await put('/user/spaceFile/delete',{
      fileIds: filesId,
    }).then((response) => {
      console.log(response);
      getSpaceFiles();
      getStorageInfo();
      setSelectedFiles([]);
      setLoading(false);
      setAlert(t('labels.fileDeleted'), 'success');
    });
  };

  const onDeleteAllFiles = async () => {
    setDeleteModal(false);
    const files = getSelectedItems();
    if (files.length) {
      filesRef.current = files;
      setLoading(true);
      setProgressDetails(files, deleteProgressRef, 'delete');
      setOpenModal('fileProgressModal');
      let deletedFilesCount = 0;
      files.map(async (file, index) => {
        const success = await deleteFileBydId(file.id, index);
        if (success) {
          deletedFilesCount++;
          const averageProgress = (deletedFilesCount / files.length) * 100;
          deleteProgressRef.current = averageProgress;
          setProgressPercentage(averageProgress);
          if (deletedFilesCount === files.length) {
            getSpaceFiles();
            // closeModal();
            setSelectedFiles([]);
            setLoading(false);
            getStorageInfo();
          }
        }
      });
    }
  };

  const onShareBulkFiles = (bulkId: string) => {
    const link = generateSharedFilesLink(bulkId);
    navigator.clipboard.writeText(link);
    setAlert(t('labels.fileCopyLink'), 'info');
  };

  return {
    isModalOpen,
    setIsModalOpen,
    selectedItems: selectedFiles,
    expandedItems,
    isLoading,
    handleCheckboxChange,
    findParentItem,
    toggleExpand,
    getSelectedItems,
    onFilesStatusChange,
    downloadMultiFiles,
    downloadFileById,
    deleteFileBydId,
    onDownloadFiles,
    onDeleteFiles,
    newDeleteFileBydId,
    onShareBulkFiles,
    onDownloadAllFiles,
    onDeleteAllFiles,
    onDeleteAllFilesNew,
    onDecryptFile,
    downloadProgress,
    onUpdateSelectedItems,
    isDeleteModal,
    setDeleteModal,
    setExpandedItems,
  };
};

export default useFileOperations;
